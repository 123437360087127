import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { tap } from "rxjs";
import Swal from "sweetalert2";
import { PublicHolidaysService } from "../../../../../@shared/services/public-holidays.service";

@Component({
  selector: "ngx-add-holiday",
  templateUrl: "./add-holiday.component.html",
  styleUrls: ["./add-holiday.component.scss"],
})
export class AddHolidayComponent implements OnInit {
  form: FormGroup;
  _snackBar: any;
  constructor(
    private fb: FormBuilder,
    public publicHolidayService: PublicHolidaysService,
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form = this.fb.group({
      name: ["", [Validators.required]],
      date: ["", [Validators.required]],
      description: [""],
    });
  }

  ngOnInit(): void {
    if (this.data.status === "update") {
      this.form = this.fb.group({
        name: this.data.data.name,
        date: new Date(this.data.data.date),
        description: this.data.data.description,
      });
    }
  }
  submitForm(): void {
    // conversion of date
    this.form.value.date = new Date(this.form.value.date);
    this.form.value.date = new Date(
      this.form.value.date.getFullYear(),
      this.form.value.date.getMonth(),
      this.form.value.date.getDate(),
      this.form.value.date.getHours(),
      this.form.value.date.getMinutes() -
      this.form.value.date.getTimezoneOffset()
    ).toISOString();

    if (this.data.status === "update") {
      this.publicHolidayService
        .updatePublicHoliday(this.data.data.id, this.form.value)
        .pipe(
          tap(() => {
            this.dialogRef.close(true);
            Swal.fire("Success!", "Designation has been Updated.", "success");
          })
        )
        .subscribe();
    } else {
      this.publicHolidayService
        .addPublicHoliday(this.form.value)
        .pipe(
          tap(
            () => {
              this.dialogRef.close(true);
              Swal.fire("Success!", "Designation has been created.", "success");
            },
            () => { }
          )
        )
        .subscribe();
    }
  }
}
