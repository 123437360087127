import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../@core/guard/auth.guard";
import { AttendanceComponent } from "./attendance.component";
import { AttendanceCorrectionComponent } from './components/attendance-correction/attendance-correction.component';
import { AttendanceScannerComponent } from "./components/attendance-scanner/attendance-scanner.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { DesignationsComponent } from "./components/designations/designations.component";
import { EmployeesComponent } from "./components/employees/employees.component";
import { LeaveTypesComponent } from "./components/leave-types/leave-types.component";
import { LeavesListComponent } from "./components/leaves-list/leaves-list.component";
import { PublicHolidaysComponent } from "./components/public-holidays/public-holidays.component";
import { SpentHoursComponent } from "./components/spent-hours/spent-hours.component";
import { StatisticsComponent } from "./components/statistics/statistics.component";

const routes: Routes = [
  {
    path: "",
    component: AttendanceComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "dashboard",
        component: DashboardComponent,
      },
      {
        path: "statistics",
        component: StatisticsComponent,
      },
      {
        path: "employees",
        component: EmployeesComponent,
      },
      {
        path: "scanner",
        component: AttendanceScannerComponent,
      },
      {
        path: "spents",
        component: SpentHoursComponent,
      },
      {
        path: "leaves",
        component: LeavesListComponent,
      },
      {
        path: "correction",
        component: AttendanceCorrectionComponent,
      },
      {
        path: "setting/designation",
        component: DesignationsComponent,
      },
      {
        path: "setting/leavetypes",
        component: LeaveTypesComponent,
      },
      {
        path: "setting/leavetypes",
        component: LeaveTypesComponent,
      },
      {
        path: "setting/public-holiday",
        component: PublicHolidaysComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AttendanceRoutingModule {}
