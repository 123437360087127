import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-mark-success',
  templateUrl: './mark-success.component.html',
  styleUrls: ['./mark-success.component.scss']
})
export class MarkSuccessComponent implements OnInit {
  @Input() data:any;

  constructor(){}

  ngOnInit(): void {
    setTimeout(() => {
      location.reload(); 
    }, 9000);
  }
}
