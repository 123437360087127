import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { tap } from 'rxjs';
import Swal from 'sweetalert2';
import { DesignationService } from '../../../../../@shared/services/designation.service';

@Component({
  selector: 'ngx-add-designation',
  templateUrl: './add-designation.component.html',
  styleUrls: ['./add-designation.component.scss']
})
export class AddDesignationComponent implements OnInit {
  form: FormGroup;
  _snackBar: any;
  constructor(private fb: FormBuilder,
    public designationService: DesignationService,
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.form = this.fb.group({
      name: ["", [Validators.required]],
      is_active: [true],
    });
  }

  ngOnInit(): void {
    if (this.data.status === 'update') {
      this.form = this.fb.group({
        name: this.data.data.name,
        is_active: this.data.data.is_active,
      });
    }

  }
  submitForm(): void {
    if (this.data.status === 'update') {
      this.designationService
      .updateDesignation(this.data.data.id, this.form.value)
      .pipe(
        tap(
          (res: any) => {
            this.dialogRef.close(true);
            Swal.fire("Success!", "Designation has been Updated.", "success");
          },
        )
      )
      .subscribe();
    } else {
      this.designationService
      .addDesignation(this.form.value)
      .pipe(
        tap(
          (res: any) => {
            this.dialogRef.close(true);
            Swal.fire("Success!", "Designation has been created.", "success");
          },
          (err) => {
          }
        )
      )
      .subscribe();
    }

  }
}
