import { AfterViewInit, Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { catchError, finalize, tap } from "rxjs";
import Swal from "sweetalert2";
import { EmployeeService } from "../../../../../@shared/services/employee.service";

@Component({
  selector: "ngx-employees-new",
  templateUrl: "./employees-new.component.html",
  styleUrls: ["./employees-new.component.scss"],
})
export class EmployeesNewComponent implements OnInit, AfterViewInit {
  form: FormGroup;
  departments: any;
  designations: any;
  employmentStatus: any = [
    {
      id: 1,
      name: "intern",
      is_active: true,
    },
    {
      id: 2,
      name: "probation",
      is_active: true,
    },
    {
      id: 3,
      name: "permanent",
      is_active: true,
    },
  ];
  employmentRoles = [
    {
      id: 1,
      name: "super admin",
    },
    {
      id: 2,
      name: "admin",
    },
    {
      id: 3,
      name: "user",
    },
  ];
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  isLoaded: boolean = false;
  empRole: any[] = [];
  showConfrimData: boolean = true;
  constructor(
    private fb: FormBuilder,
    private employeeService: EmployeeService,
    private dialogRef: MatDialogRef<any>,
    private _snackBar: MatSnackBar
  ) {
    this.form = this.fb.group({
      name: ["", [Validators.required, Validators.minLength(3)]],
      password: ["", [Validators.required, Validators.minLength(5)]],
      confirm_password: [
        "",
        [Validators.required, this.passwordMatchValidator()],
      ],
      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ],
      ],
      phone: [""],
      is_active: [true],
      youtrack_integration: [true],
      designation: this.fb.group({
        id: ["", [Validators.required]],
      }),
      department: this.fb.group({
        id: ["", [Validators.required]],
      }),
      date_joined: [""],
      date_of_permanent: [""],
      employment_status: ["", [Validators.required]],
      roles: [[]],
    });
  }

  ngOnInit(): void {
    this.initData();
  }

  initData() {
    this.employeeService.getAllRoles().subscribe((res) => {
      this.employmentRoles = res;
    });
  }
  selectRoles(roles) {
    if (this.empRole.length > 0) {
      let add = true;
      this.empRole.forEach((ele, index) => {
        if (ele === roles) {
          this.empRole.splice(index, 1);
          add = false;
        }
      });
      if (add) {
        this.empRole.push(roles);
      }
    } else {
      this.empRole.push(roles);
    }

    this.form.patchValue({
      roles: this.empRole,
    });
  }

  ngAfterViewInit(): void {
    this.getDepartmentsDesignationsList();
  }

  // custom validator for password confirmation
  passwordMatchValidator(): ValidatorFn {
    return (control: FormControl) => {
      if (!control || !control.parent) {
        return null;
      }
      return control.parent.get("password").value === control.value
        ? null
        : { mismatch: true };
    };
  }

  getDepartmentsDesignationsList(): void {
    this.employeeService
      .getDepartmentsList()
      .pipe(
        tap((res: any) => {
          this.departments = res;
        }),
        finalize(() => {
          this.employeeService
            .getDesignationsList()
            .pipe(
              tap((res: any) => {
                this.designations = res;
              }),
              finalize(() => {
                this.isLoaded = true;
              })
            )
            .subscribe();
        })
      )
      .subscribe();
  }

  getEmployeeStatus(status) {
    if (status === "intern" || status === "probation") {
      this.showConfrimData = false;
    } else {
      this.showConfrimData = true;
    }
  }

  submitForm(): void {
    let params = {
      name: this.form.get("name").value,
      email: this.form.get("email").value,
      phone: this.form.get("phone").value,
      is_active: this.form.get("is_active").value,
      date_joined: this.form.get("date_joined").value,
      date_of_permanent: this.form.get("date_of_permanent").value,
      employment_status: this.form.get("employment_status").value,
      department: this.form.get("department").value,
      designation: this.form.get("designation").value,
      password: this.form.get("password").value,
      roles: this.form.get("roles").value,
      youtrack_integration: this.form.get("youtrack_integration").value,
    };
    this.isLoaded = false;
    this.employeeService
      .createEmployee(params)
      .pipe(
        tap(
          (res: any) => {
            this.dialogRef.close({refresh:true});
            this.isLoaded = true;

            Swal.fire("Success!", "Employee has been created.", "success");
          },
        ),
        catchError(async (err) => this.errorSnack())
      )
      .subscribe();
  }

  objectComparisonFunction = function (option: any, value: any): boolean {
    return option?.id === value?.id;
  };

  validateNumber() {
    this.form.patchValue({
      phone: this.form.value.phone
        .replace(/[^0-9.]/g, "")
        .replace(/(\..*?)\..*/g, "$1")
        .replace(/^0[^.]/, "0"),
    });
  }
  public errorSnack() {
    this._snackBar.open("  Email or username already in use.", "Ok", {
      horizontalPosition: "center",
      verticalPosition: "top",
    });
    this.isLoaded = true;
  }
}
