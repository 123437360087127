<div *ngIf="isLoaded">
    <label class="title h4">Spent Hours</label>
    <div class="d-flex justify-content-between mb-3">
        <div>
            <input class="search" type="text" nbInput fullWidth placeholder="Employee" (input)="onSearch($event)">
        </div>
        <div>
            <input (ngModelChange)="onDateSelection($event)" [(ngModel)]="selectedDate" class="datepicker" fullWidth
                nbInput placeholder="Enter a date range" [nbDatepicker]="rangepicker">
            <nb-rangepicker #rangepicker></nb-rangepicker>

        </div>
    </div>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z1 w-100">

        <!-- Employee Column -->
        <ng-container matColumnDef="Employee">
            <th mat-header-cell *matHeaderCellDef> Employee </th>
            <td mat-cell *matCellDef="let element">{{element.name}} </td>
        </ng-container>

        <!-- Spent Hours Column -->
        <ng-container matColumnDef="Spent Hours">
            <th mat-header-cell *matHeaderCellDef> Spent Hours </th>
            <td mat-cell *matCellDef="let element" (click)="searchInput(element.id)">
                {{showTimeSymbols(element.totalTimeSpent)}} </td>
        </ng-container>

        <!-- Average Check In Column -->
        <ng-container matColumnDef="Average Check In">
            <th mat-header-cell *matHeaderCellDef> Average Check In </th>
            <td mat-cell *matCellDef="let element" (click)="searchInput(element.id)"> {{element.avgCheckIn|formatTime}}
            </td>
        </ng-container>

        <!--Average Check Out Column -->
        <ng-container matColumnDef="Average Check Out">
            <th mat-header-cell *matHeaderCellDef> Average Check Out </th>
            <td mat-cell *matCellDef="let element" (click)="searchInput(element.id)"> {{element.avgCheckOut|formatTime}}
            </td>
        </ng-container>

        <!--Days Column -->
        <ng-container matColumnDef="Days">
            <th mat-header-cell *matHeaderCellDef> Days </th>
            <td mat-cell *matCellDef="let element" (click)="searchInput(element.id)"> {{element.days}} </td>
        </ng-container>

        <!--Average One Day -->
        <ng-container matColumnDef="Average One Day">
            <th mat-header-cell *matHeaderCellDef> Average One Day </th>
            <td mat-cell *matCellDef="let element" (click)="searchInput(element.id)">
                {{showTimeSymbols(element.avgWorkingHours)}}
            </td>
        </ng-container>


        <!-- no record found -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="9999">No Record found</td>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
</div>

<ngx-mat-progress-bar [title]="'Please Wait...'" [isLoaded]="isLoaded"></ngx-mat-progress-bar>
