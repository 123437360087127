import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { tap } from "rxjs";
import Swal from "sweetalert2";
import {
  modalConfig,
  ModalEnum,
} from "../../../../@shared/models/modalConfigs";
import { PublicHolidaysService } from "../../../../@shared/services/public-holidays.service";
import { AddHolidayComponent } from "./add-holiday/add-holiday.component";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "ngx-public-holidays",
  templateUrl: "./public-holidays.component.html",
  styleUrls: ["./public-holidays.component.scss"],
})
export class PublicHolidaysComponent implements OnInit {
  displayedColumns: string[] = ["name", "description", "date", "action"];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isLoaded: boolean = false;
  totalItems = 0;
  itemsPerPage = 10;
  currentPage = 1;
  totalPages: number = 0;

  constructor(
    public publicHolidayService: PublicHolidaysService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {}
  ngAfterViewInit(): void {
    this.getPublicHolidayList();
  }

  getPublicHolidayList(): void {
    this.isLoaded = false;
    this.publicHolidayService
      .getPublicHolidays()
      .pipe(
        tap((res: any) => {
          this.dataSource = res.data;
          this.totalItems = res.meta.total;
          this.itemsPerPage = res.meta.per_page;
          this.currentPage = res.meta.current_page;
          this.totalPages = res.links.next;
          this.isLoaded = true;
        })
      )
      .subscribe();
  }

  loadHolidays(page: number = 1): void {
    this.isLoaded = false;
    this.publicHolidayService
      .getPublicHolidays(page)
      .pipe(
        tap((res: any) => {
          this.dataSource = res.data;
          this.totalItems = res.meta.total;
          this.itemsPerPage = res.meta.per_page;
          this.currentPage = res.meta.current_page;
          this.isLoaded = true;
        })
      )
      .subscribe();
  }

  addHoliday(): void {
    const modal = this.dialog.open(
      AddHolidayComponent,
      modalConfig(
        {
          data: "",
          panelClass: ["animate__animated", "animate__slideInRight"],
        },
        ModalEnum.ModalSmall
      )
    );
    modal.afterClosed().subscribe((res) => {
      //refresh tab data based on current active tab
      if (res) {
        this.getPublicHolidayList();
      }
    });
  }

  deleteHoliday(id: any): void {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.isLoaded = false;
        this.publicHolidayService
          .deletePublicHoliday(id)
          .pipe(
            tap((res: any) => {
              this.getPublicHolidayList();
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
            })
          )
          .subscribe();
      }
    });
  }

  updateHoliday(id: any, data: any): void {
    const modal = this.dialog.open(
      AddHolidayComponent,
      modalConfig(
        {
          data: { data, status: "update" },
          panelClass: ["animate__animated", "animate__slideInRight"],
        },
        ModalEnum.ModalSmall
      )
    );
    modal.afterClosed().subscribe((res) => {
      //refresh tab data based on current active tab
      if (res) {
        this.getPublicHolidayList();
      }
    });
  }

  onPageChange(event: any): void {
    this.loadHolidays(event.pageIndex + 1);
  }
}
