import * as moment from "moment";
import { environment } from "../../../environments/environment";
import { dateIso } from "./dateiso";
import { dateFormat, daysFormat, timeFormat } from "./moment";

export function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
export function convertToFormData(today, file, latitude, longitude) {
  let formData = new FormData();
  formData.append(
    "timestamp",
    moment(dateIso(today, true, environment.timezone)).format(
      "YYYY-MM-DD HH:mm:ss"
    )
  );
  formData.append("image", file);
  formData.append("latitude", latitude);
  formData.append("longitude", longitude);
  return formData;
}
export function convertBase64ToBlob(Base64Image: string) {
  // split into two parts
  const parts = Base64Image.split(";base64,");
  // hold the content type
  const imageType = parts[0].split(":")[1];
  // decode base64 string
  const decodedData = window.atob(parts[1]);
  // create unit8array of size same as row data length
  const uInt8Array = new Uint8Array(decodedData.length);
  // insert all character code into uint8array
  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i);
  }
  // return blob image after conversion
  return new Blob([uInt8Array], { type: imageType });
}
//calender Event Handler
export function calenderEventHandler(data, type, user) {
  let records = [];
  if (type == "attendance") {
    data.forEach((element) => {
      if (element.check_in) {
        records.push({
          id: element.id,
          start: dateFormat(element.check_in),
          end: dateFormat(element.check_out),
          title: eventTitle(element),
          classNames: eventClass(element),
          text: eventTitle(element),
          type: "attendance",
          email: user.email,
          description: "Spend Time:" + timeFormat(element.working_hours),
        });
      }
    });
  } else {
    data.forEach((element) => {
      if (element) {
        // let date : any  = new Date(element.date_to)
        // date.setDate(date.getDate() + 1);
        // date = date.toISOString()
        records.push({
          id: element.id,
          start: dateFormat(element.date_from),
          end: dateFormat(element.date_to),
          title: element.type,
          durationType: element.duration,
          classNames: element.type ? element.type.replace(" ", "") : "",
          text: element.type,
          type: "leaves",
          email: user.email,
          description: element.description,
        });
      }
    });
  }
  return records;
}
export function eventTitle(element) {
  let workingFrom = null;
  if (element.working_from === "home") {
    workingFrom = "WFH";
  } else {
    workingFrom = "In Office";
  }
  return (
    timeFormat(element.check_in) +
    " - " +
    (element.check_out ? timeFormat(element.check_out) : workingFrom)
  );
}
export function eventClass(element) {
  if (element.working_hours) {
    switch (true) {
      case element.working_hours > "09:00:00":
        return "present";
      case element.working_hours >= "08:00:00" &&
        element.working_hours <= "9:00:00":
        return "ontime";
    //   case element.working_hours > "10:00:00":
    //     return "high";
    //   case element.working_hours < "09:00:00":
    //     return "low";
      case element.working_hours < "08:00:00":
        return "danger";
    }
  } else {
    return "pending";
  }
}
