import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-custome-checkbox',
  templateUrl: './custome-checkbox.component.html',
  styleUrls: ['./custome-checkbox.component.scss']
})
export class CustomeCheckboxComponent implements OnInit {

  @Input() status:any
  constructor() { }

  ngOnInit(): void {
  }

}
