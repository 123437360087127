<section class="statistics">
    <mat-progress-bar *ngIf="!isLoad" mode="indeterminate"></mat-progress-bar>
    <div *ngIf="!isEmployee">
        <ng-container *ngIf="(users$ | async) as user">
            <nb-select class="w-25 select-position-set" [(ngModel)]="selectedUser" (selectedChange)="onUserSelection()">
                <nb-option value="option">Select Employee</nb-option>
                <nb-option-group *ngFor="let item of user" [ngClass]="item.is_active!=isInActive?'hide':'show'">
                    <nb-option [value]="item" *ngIf="item.is_active===isInActive">{{ item.name }}</nb-option>
                </nb-option-group>
            </nb-select>
            <nb-checkbox class="checkbox-position-set" status="primary" [(ngModel)]="isInActive">
                Active Employees
            </nb-checkbox>
        </ng-container>
    </div>
    <full-calendar *ngIf="isLoad" [options]="calendarOptions"></full-calendar>
</section>