<div>
  <div class="row">
    <div class="col-md-6">
      <h6 mat-dialog-title>Add Designation</h6>
    </div>
    <div class="col-md-6">
      <nb-icon
        mat-dialog-close
        class="float-right close-icon"
        icon="close"
      ></nb-icon>
    </div>
  </div>

  <form [formGroup]="form" (ngSubmit)="submitForm()" class="form">
    <mat-dialog-content class="mat-typography">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="exampleInputName" class="label">Designation<span *ngIf="form.get('name')?.errors?.required">*</span></label>
            <input type="text" nbInput fullWidth id="exampleInputName" placeholder="Enter designation" formControlName="name"/>
            <small class="text-danger" *ngIf="form.get('name').hasError('required') && form.get('name').touched">
              This field is required.
            </small>
            <small class="text-danger" *ngIf="form.get('name').hasError('minlength') && form.get('name').touched">
              The minimum length for this field is
              {{ form.get("name").errors.minlength.requiredLength }}
              characters.
            </small>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <nb-checkbox status="success" formControlName="is_active">Active</nb-checkbox>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button size="small" mat-dialog-close nbButton class="mr-2">
        Cancel
      </button>
      <button
        size="small"
        [disabled]="!form.valid"
        nbButton
        status="primary"
        class="employee-btn"
        type="submit"
      >
        Save
      </button>
    </mat-dialog-actions>
  </form>
</div>
