<div>
    <div class="row">
        <div class="col-md-6">
            <h6 mat-dialog-title>QR Code</h6>
        </div>
        <div class="col-md-6">
            <nb-icon mat-dialog-close class="float-right close-icon" icon="close"></nb-icon>
        </div>
    </div>
    <mat-dialog-content class="mat-typography">
        <div class="row">
            <div class="col-md-12 qr d-flex justify-content-center">
                <qrcode [elementType]="'canvas'" [scale]="1" #parent qrdata="{{email}}" [width]="250"
                    [errorCorrectionLevel]="'M'">
                </qrcode>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button size="small" nbButton status="primary" class="employee-btn" type="submit"
            (click)="saveAsImage(parent)">Download
        </button>
    </mat-dialog-actions>
</div>