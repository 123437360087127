import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ToastService } from '../../../@core/services/toast.service';

@Component({
  selector: 'ngx-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.scss']
})
export class AttendanceComponent implements OnInit {
  public showQR:boolean = true;
  public attendanceData:any;
  isLoading : boolean = false;
  counter : number = 6;
  intervalPassed: Subject<void> = new Subject<void>();
  constructor(
    private _toastService: ToastService
  ) { }

  ngOnInit(): void {
  }

  QRcodeHandler(valueEmitted){
    this.showQR = false;
    this.attendanceData = valueEmitted;
  }
  capturingPicture(event){
    this._toastService.showToast('info', 'Capturing Image', "Please wait you image is being captured");
    this.isLoading = event;
    let counterInterval = setInterval(() => {
      this.counter--; 
      if(this.counter === 0 ){
        this.counter = 6;
        this.isLoading = false;
        this.intervalPassed.next();
        clearInterval(counterInterval);
      }
    }, 1000); 
  }
  
}
