import * as moment from "moment-timezone";

export function dateIso(date: string|number|Date, utcToLocal: boolean = false, timezone? : string): Date|any {

  if (!date) {
    return date;
  }

  if (!utcToLocal) {
    return moment(date).toDate();
  } else {
    return moment.utc(date).tz(timezone).toDate();
  }
}
