<label class="title h4">Annual Leave Manager</label>
<div *ngIf="isLoaded">
  <form >
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label for="exampleInputDate" class="label">Start Date</label>
          <input
            id="exampleInputDate"
            fullWidth
            [(ngModel)]="startDateControl"
            name = "startDate"
            nbInput
            placeholder="Select Start date"
            [nbDatepicker]="formpicker"
          />
          <nb-datepicker #formpicker></nb-datepicker>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="exampleInputDate" class="label">End Date</label>
          <input
            id="exampleInputDate"
            fullWidth
            [(ngModel)]="endDateControl"
            name = "endDate"
            nbInput
            placeholder="Select End date"
            [nbDatepicker]="formpicker1"
          />
          <nb-datepicker #formpicker1></nb-datepicker>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <button
          nbButton
          status="primary"
          class="btn-primary dateFilter"
          (click)="filterData()"
        >
          Filter
        </button>
        </div>

      </div>
    </div>
  </form>



  <table mat-table [dataSource]="dataSource" class="mat-elevation-z1 w-100">
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">
        {{ element.name }}
      </td>
    </ng-container>

    <!-- annual Column -->
    <ng-container matColumnDef="annual">
      <th mat-header-cell *matHeaderCellDef>Annual Leave</th>
      <td mat-cell *matCellDef="let element">
        {{ element?.leave?.annual }}
      </td>
    </ng-container>

    <!-- sick Column -->
    <ng-container matColumnDef="sick">
      <th mat-header-cell *matHeaderCellDef>Sick Leaves</th>
      <td mat-cell *matCellDef="let element">
        {{ element?.leave?.sick }}
      </td>
    </ng-container>

    <!-- casual Column -->
    <ng-container matColumnDef="casual">
      <th mat-header-cell *matHeaderCellDef>Casual Leave</th>
      <td mat-cell *matCellDef="let element">
        {{ element?.leave?.casual }}
      </td>
    </ng-container>

    <!-- unpaid Column -->
    <ng-container matColumnDef="unpaid">
      <th mat-header-cell *matHeaderCellDef>Un-Paid Leave</th>
      <td mat-cell *matCellDef="let element">
        {{ element?.leave?.unpaid ? element?.leave?.unpaid : 0 }}
      </td>
    </ng-container>

    <!-- no record found -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="9999">No Record found</td>
    </tr>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <!-- <mat-paginator
    #paginator
    [length]="length"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    [pageSizeOptions]="[5, 10, 25, 100]"
    (page)="getDataOnPageChange($event)"
  >
  </mat-paginator> -->
</div>
<ngx-mat-progress-bar
  [title]="'Please Wait...'"
  [isLoaded]="isLoaded"
></ngx-mat-progress-bar>
