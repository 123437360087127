<label class="title h4">Public Holidays</label>
<div class="d-flex justify-content-between">
    <div>
    </div>
    <div>
        <button nbButton status="primary" class="employee-btn" (click)="addHoliday()">
            Add
        </button>
    </div>
</div>
<table mat-table [dataSource]="dataSource" class="mt-3 mat-elevation-z1 w-100">
    <!-- Name Column -->
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="col-md-4">Name</th>
        <td mat-cell *matCellDef="let element" (click)="updateHoliday(element.id, element)">
            {{ element?.name }}
        </td>
    </ng-container>
    <!-- Name Column -->

    <!-- Description Column -->
    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef class="col-md-5">Description</th>
        <td mat-cell *matCellDef="let element" (click)="updateHoliday(element.id, element)">
            {{ element?.description }}
        </td>
    </ng-container>
    <!-- Name Column -->

    <!-- Date  column -->
    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef class="col-md-2">Date</th>
        <td mat-cell *matCellDef="let element" (click)="updateHoliday(element.id, element)">
            {{ element?.date |date:'longDate' }}
        </td>
    </ng-container>
    <!-- Date  column -->

    <!-- Action Column -->
    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="col-md-2"></th>
        <td mat-cell *matCellDef="let element" class="text-center">
            <i (click)="deleteHoliday(element.id)" class="fa fa-trash color-red" aria-hidden="true"></i>
        </td>
    </ng-container>
    <!-- no record found -->
    <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9999">No Record found</td>
    </tr>
    <!-- no record found -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

</table>

<mat-paginator [length]="totalItems" [pageSize]="itemsPerPage" [pageSizeOptions]="[5, 10, 20]"
    (page)="onPageChange($event)"></mat-paginator>