import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { catchError, finalize, of, tap } from "rxjs";
import Swal from "sweetalert2";
import { ToastService } from "../../../../@core/services/toast.service";
import { Globals } from "../../../../gloabls";
import { dateFormat } from "../../../models/moment";
import {
  modalEntityType,
  statisticsDateMapping,
  statisticsDateMappingLeaves,
  statisticsMaxDayMapping,
  statisticsMinDayMapping,
} from "../../../models/statisticsModal";
import { StaticticsService } from "../../../services/statistics.service";

@Component({
  selector: "ngx-statistics-new",
  templateUrl: "./statistics-new.component.html",
  styleUrls: ["./statistics-new.component.scss"],
})
export class StatisticsNewComponent implements OnInit {
  public type = "option";
  public leaveType = "option";
  public duration = "option";
  public attendance: any;
  proceedLeave: boolean = false;
  isLeaveremaining: boolean = true;
  remainingErrorMsg: string = "";
  leaveTypes: any[] = [];
  public isLoad: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<StatisticsNewComponent>,
    private dialog: MatDialog,
    private _staticticsService: StaticticsService,
    private globals: Globals,
    private _toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.type = this.data.extendedProps
      ? this.data.extendedProps.type
      : this.type;
    this.loadEntity(this.type);
    this.loadEntity(this.duration);

    this.initData();
  }

  initData() {
    this._staticticsService.getLeaveTypes().subscribe((res) => {
      this.leaveTypes = res;
      this.leaveTypes.map((res) => {
        res.value = res.name;
        res.name = res.name.charAt(0).toUpperCase() + res.name.slice(1);
        this.isLoad = true;
      });
    });
  }
  //load entity
  public loadEntity(type): void {
    this.attendance = modalEntityType(this.data, this.type);
    // if (this.attendance.date_to != undefined) {
    //   let date: any = new Date(this.attendance.date_to);
    //   date.setDate(date.getDate())
    //   date = date.toISOString();
    //   this.attendance.date_to = date;
    // }
  }
  //on submit
  public onSubmit(type): void {
    switch (type) {
      case "attendance":
        this._staticticsService
          .markAttendance(
            statisticsDateMapping(this.attendance),
            this.data.user.id
          )
          .pipe(
            tap(() => {
              this.dialogRef.close(this.data.user);
              Swal.fire(
                "Success!",
                "Your " + type + " has been save.",
                "success"
              );
            }),
            catchError((err) => {
              this.dialogRef.close(this.data.user);
              Swal.fire("Not Updated!", "Something is wrong", "error");
              return of(err);
            })
          )
          .subscribe();
        break;
      case "leaves":
        this._staticticsService
          .markLeave(statisticsDateMappingLeaves(this.attendance))
          .pipe(
            tap(() => {
              this.dialogRef.close(this.data.user);
              Swal.fire(
                "Success!",
                "Your " + type + " has been save.",
                "success"
              );
            }),
            catchError((err) => {
              this.dialogRef.close(this.data.user);
              Swal.fire("Not Updated!", "Something is wrong", "error");
              return of(err);
            })
          )
          .subscribe();
        break;
      default:
        return;
    }
  }
  //on Update
  public onUpdate(type): void {
    switch (type) {
      case "attendance":
        this._staticticsService
          .updateAttendance(
            statisticsDateMapping(this.attendance),
            this.data.eventId
          )
          .pipe(
            tap(() => {
              this.dialogRef.close(this.data.user);
              Swal.fire(
                "Update!",
                "Your " + type + " has been update.",
                "success"
              );
            }),
            catchError((err) => {
              this.dialogRef.close(this.data.user);
              Swal.fire("Not Updated!", "Something is wrong", "error");
              return of(err);
            })
          )
          .subscribe();
        break;
      case "leaves":
        this._staticticsService
          .updateLeave(
            statisticsDateMappingLeaves(this.attendance),
            this.data.eventId
          )
          .pipe(
            tap(() => {
              this.dialogRef.close(this.data.user);
              Swal.fire(
                "Update!",
                "Your " + type + " has been update.",
                "success"
              );
            }),
            catchError((err) => {
              this.dialogRef.close(this.data.user);
              Swal.fire("Not Updated!", "Something is wrong", "error");
              return of(err);
            })
          )
          .subscribe();
        break;
      default:
        return;
    }
  }
  //on delete
  public onDelete(type): void {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        switch (type) {
          case "attendance":
            this._staticticsService
              .deleteAttendance(this.data.eventId)
              .pipe(finalize(() => this.dialogRef.close(this.data.user)))
              .subscribe();
            break;
          case "leaves":
            this._staticticsService
              .deleteLeaves(this.data.eventId)
              .pipe(finalize(() => this.dialogRef.close(this.data.user)))
              .subscribe();
            break;
          default:
            return;
        }
        Swal.fire("Deleted!", "Your " + type + " has been deleted.", "success");
      }
    });
  }
  //on type select
  public onTypeSelection(): void {
    this.loadEntity(this.type);
  }
  //dayget formate
  public onCurrentMinDaySet(event) {
    return statisticsMinDayMapping(event);
  }
  public onCurrentMaxDaySet(event) {
    return statisticsMaxDayMapping(event);
  }
  public onLeaveSelection(): void {
    this._staticticsService
      .getLeaveQuota(this.data.user.id)
      .subscribe((res) => {
        if (this.attendance.type === "annual") {
          this.isLeaveAvailable(this.attendance.duration, res.remaining.annual);
        } else if (this.attendance.type === "casual") {
          this.isLeaveAvailable(this.attendance.duration, res.remaining.casual);
        } else if (this.attendance.type === "sick") {
          this.isLeaveAvailable(this.attendance.duration, res.remaining.sick);
        } else {
          this.proceedLeave = true;
          this.isLeaveremaining = true;
        }
      });
  }

  isLeaveAvailable(duration, remaining) {
    this.proceedLeave = false;
    this.isLeaveremaining = true;
    this.remainingErrorMsg = "Leave Quota not available for this Leave Type";

    switch (duration) {
      case "full":
        if (remaining >= 1) {
          this.proceedLeave = true;
        } else {
          this.isLeaveremaining = false;
          // this._toastService.showToast('danger',"Full Leave","User has no full leave available")
        }
        break;
      case "half":
        if (remaining >= 0.5) {
          this.proceedLeave = true;
        } else {
          this.isLeaveremaining = false;

          // this._toastService.showToast('danger',"Half Leave","User has no half leave available")
        }
        break;
      case "short":
        if (remaining >= 0.34) {
          this.proceedLeave = true;
        } else {
          this.isLeaveremaining = false;
          // this._toastService.showToast('danger',"Short Leave","User has no short leave available")
        }
        break;
      default:
        return;
    }
  }
}
