import { LeaveListService } from "./../../../../@shared/services/leave-list.service";
import { AfterViewInit, Component, OnInit } from "@angular/core";
import { map, tap } from "rxjs";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";

@Component({
  selector: "ngx-leaves-list",
  templateUrl: "./leaves-list.component.html",
  styleUrls: ["./leaves-list.component.scss"],
})
export class LeavesListComponent implements OnInit, AfterViewInit {
  startDateControl: any = new Date(moment().startOf('year').format('YYYY-MM-DD'));
  endDateControl: any = new Date(moment().format('YYYY-MM-DD'));

  // startDateControl: FormControl = new FormControl('',[Validators.required])
  // endDateControl: FormControl = new FormControl('',[Validators.required])

  // dateForm: FormGroup = new FormGroup({
  //   startDate: this.startDateControl,
  //   endDate : this.endDateControl
  // })


  displayedColumns: string[] = ["name", "annual", "sick", "casual", "unpaid"];
  dataSource: any[] = [];
  sickLeave: any = [];
  casualLeave: any = [];
  annualLeave: any = [];
  isLoaded: boolean = false;
  constructor(public leaveManagerService: LeaveListService) {}

  ngOnInit(): void {

  }
  ngAfterViewInit(): void {
    this.getAllUserleaveList();
    // this.getDesignationsList();
  }
  getAllUserleaveList() {
    this.isLoaded = false;
    this.leaveManagerService
      .getAllUsersLeave(moment(this.startDateControl).format("YYYY-MM-DD"),moment(this.endDateControl).format("YYYY-MM-DD") )
      .pipe(
        tap((res: any) => {
          this.dataSource = res.data;
          this.isLoaded = true;
        })
      )
      .subscribe();
  }
  getDesignationsList(): void {
    this.isLoaded = false;
    this.leaveManagerService
      .getAll("2022-08-01", "2022-08-20")
      .pipe(
        tap((res: any) => {
          this.dataSource = res.data;
          const count = this.dataSource.filter(
            (item) => item.type === "sick"
          ).length;
          const count2 = this.dataSource.filter(
            (item) => item.type === "casual"
          ).length;
          this.isLoaded = true;
        })
      )
      .subscribe();
  }

  filterData() {
    this.startDateControl = moment(this.startDateControl).format("YYYY-MM-DD");
    this.endDateControl = moment(this.endDateControl).format("YYYY-MM-DD");
    this.isLoaded = false;
    this.leaveManagerService
      .getAllUsersLeave(this.startDateControl,this.endDateControl)
      .pipe(
        tap((res: any) => {
          this.dataSource = res.data;
          this.isLoaded = true;
        })
      )
      .subscribe();
  }


}
