import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { tap } from "rxjs";
import Swal from "sweetalert2";
import {
  modalConfig,
  ModalEnum,
} from "../../../../@shared/models/modalConfigs";
import { CorrectionRequestService } from "../../../../@shared/services/correction-request.service";
import { Globals } from "../../../../gloabls";
import { RequestCorrectionComponent } from "./request-correction/request-correction.component";

@Component({
  selector: "ngx-attendance-correction",
  templateUrl: "./attendance-correction.component.html",
  styleUrls: ["./attendance-correction.component.scss"],
})
export class AttendanceCorrectionComponent implements OnInit {
  form: FormGroup;
  roles: any = [];
  isEmployee: boolean = false;
  displayedColumns: string[] = [
    "name",
    "reason",
    "checkIn",
    "checkOut",
    "date",
    "status",
    "action",
  ];
  dataSource: any[];
  isLoaded: boolean = false;
  constructor(
    private dialog: MatDialog,
    private globals: Globals,
    private correctionRequestService: CorrectionRequestService
  ) {}

  ngOnInit(): void {
    this.isLoaded = true;
    this.roles = this.globals.user.roles;
    if (this.roles.includes("employee") && this.roles.length === 1) {
      this.isEmployee = true;
    }
    this.onLoadEmployeeRequest();
  }
  public onLoadEmployeeRequest(): void{
    if(this.isEmployee){
      this.getCorrectionRequestList('');
    }else{
      this.getCorrectionRequestList('/admin');
    }
  }
  public onUpdateEmployeeRequest(data): void{
    if(this.isEmployee){
      this.updateCorrectionRequest('',data);
    }else{
      this.updateCorrectionRequest('/admin',data);
    }
  }
  public getCorrectionRequestList(type): void {
    this.correctionRequestService.getAttendanceRequestEmployee(type)
      .pipe(
        tap((res: any) => {
          this.dataSource = res.data;
        })
    ).subscribe();
  }

  updateCorrectionRequest(type,data): void {
    const modal = this.dialog.open(RequestCorrectionComponent,modalConfig(
    {
      data: { data, status: "update", type: type },
      panelClass: ["animate__animated", "animate__slideInRight"],
    },ModalEnum.ModalSmall));
    modal.afterClosed().subscribe((res) => {
      //refresh tab data based on current active tab
      if (res) {
        this.onLoadEmployeeRequest();
      }
    });
  }

  requestCorrection(): void {
    const modal = this.dialog.open(
      RequestCorrectionComponent,
      modalConfig(
        {
          data: "",
          panelClass: ["animate__animated", "animate__slideInRight"],
        },
        ModalEnum.ModalSmall
      )
    );
    modal.afterClosed().subscribe((res) => {
      //refresh tab data based on current active tab
      if (res) {
        this.onLoadEmployeeRequest();
      }
    });
  }

  deleteCorrectionRequest(id): void {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.isLoaded = false;
        this.correctionRequestService
          .deleteAttendanceRequest(id)
          .pipe(
            tap((res: any) => {
              this.onLoadEmployeeRequest();
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
            })
          )
          .subscribe();
      }
    });
  }
}
