<ng-container *ngIf="(userAttendance$ | async) as userAttendance">
    <nb-card>
        <nb-card-header>
            <label class="title h5">Status</label>
        </nb-card-header>
        <div class="mt-20 row">
            <div class="col-md-4">
                <nb-card class="mlr-40">
                    <div class="p-20 d-flex">
                        <div class="checkin-bg-color">
                            <i class="fas fa-user-check"></i>
                        </div>
                        <div class="ml-20 a-self-center">
                            <div class="title h5">Check In</div>
                            <div class="status paragraph-1" *ngIf="userAttendance.length">{{dateFormater(userAttendance[0].check_in)}}</div>
                        </div>
                    </div>
                </nb-card>
            </div>
            <div class="col-md-4">
                <nb-card class="mlr-40">
                    <div class="p-20 d-flex">
                        <div class="checkout-bg-color">
                            <i class="fas fa-user-clock"></i>
                        </div>
                        <div class="ml-20 a-self-center">
                            <div class="title h5">Check Out</div>
                            <div class="status paragraph-1" *ngIf="userAttendance.length">{{dateFormater(userAttendance[0].check_out) || 'In Office'}}</div>
                        </div>
                    </div>
                </nb-card>
            </div>
            <div class="col-md-4">
                <nb-card class="mlr-40">
                    <div class="p-20 d-flex m-center">
                        <a (click)="saveAsImage(parent)">
                            <qrcode [elementType]="'canvas'" [scale]="1" #parent [qrdata]="globals.user.email" [width]="100" [errorCorrectionLevel]="'M'"></qrcode>
                        </a>
                    </div>
                </nb-card>
            </div>
        </div>
    </nb-card>

    <!-- <nb-card>
      <nb-card-header>
          <label class="title h5">Leave Stats</label>
      </nb-card-header>
      <div class="mt-20 row">
          <div class="col-md-4">
              <nb-card class="mlr-40">
                  <div class="p-20 d-flex">
                      <div class="  checkin-bg-color">
                          <div class="title h5"> Paid Leaves</div>
                          <div>12</div>
                      </div>
                  </div>
              </nb-card>
          </div>
          <div class="col-md-4">
              <nb-card class="mlr-40">
                  <div class="p-20 d-flex">
                      <div class="checkout-bg-color ">
                          <div class="title h5">Casual Leaves</div>
                          <div>6</div>
                      </div>
                  </div>
              </nb-card>
          </div>
          <div class="col-md-4">
            <nb-card class="mlr-40">
                <div class="p-20 d-flex">
                    <div class="checkout-bg-color ">
                        <div class="title h5">Sick Leaves</div>
                        <div>6</div>
                    </div>
                </div>
            </nb-card>
          </div>
      </div>
    </nb-card> -->

    <nb-card>
      <nb-card-header>
          <span>
            <label class="title leaveStatsHeading h5">Leave Stats</label>
            <mat-progress-bar *ngIf="!isLoad" mode="indeterminate"></mat-progress-bar>
            <div *ngIf="!isEmployee">
              <ng-container *ngIf="(users$ | async) as user">
                  <nb-select class="w-25 select-position-set" [(ngModel)]="selectedUser">
                      <nb-option value="option">Select Employee</nb-option>
                      <nb-option-group *ngFor="let item of user" [ngClass]="item.is_active!=isInActive?'hide':'show'">
                          <nb-option [value]="item" *ngIf="item.is_active===isInActive">{{ item.name }}</nb-option>
                      </nb-option-group>
                  </nb-select>
              </ng-container>
            </div>
          </span>
      </nb-card-header>
      <div class="mt-20 row justify-content-around" *ngIf="selectedUser">
          <div class="col-md-2 d-flex justify-content-center">
              <nb-card class="mlr-20">
                  <div class="p-20 d-flex d-flex justify-content-center">
                      <div class="checkin-bg-color leavecardsize d-flex justify-content-center">
                        <div >
                          <div class="title">Casual Leaves</div>
                          <br>
                          <div class="title d-flex justify-content-center" >{{selectedUser?.leave?.casual}}&nbsp;/&nbsp;{{selectedUser?.quota?.casual}}</div>
                        </div>
                      </div>
                  </div>
              </nb-card>
          </div>
          <div class="col-md-2 d-flex justify-content-center">
              <nb-card class="mlr-20">
                <div class="p-20 d-flex d-flex justify-content-center">
                    <div class="checkout-bg-color leavecardsize d-flex justify-content-center">
                      <div >
                        <div class="title">Sick  Leaves</div>
                        <br>
                        <div class="title d-flex justify-content-center" >{{selectedUser?.leave?.sick}}&nbsp;/&nbsp;{{selectedUser?.quota?.sick}}</div>
                      </div>
                    </div>
                </div>
              </nb-card>
          </div>
          <div class="col-md-2 d-flex justify-content-center">
              <nb-card class="mlr-20">
                <div class="p-20 d-flex d-flex justify-content-center">
                    <div class="leaves-bg-color leavecardsize d-flex justify-content-center">
                      <div >
                        <div class="title">Annual Leaves</div>
                        <br>
                        <div class="title d-flex justify-content-center" >{{selectedUser?.leave?.annual}}&nbsp;/&nbsp;{{selectedUser?.quota?.annual}}</div>
                      </div>
                    </div>
                </div>
              </nb-card>
          </div>
          <div class="col-md-2 d-flex justify-content-center">
            <nb-card class="mlr-20">
              <div class="p-20 d-flex d-flex justify-content-center">
                  <div class="checkout-bg-color leavecardsize d-flex justify-content-center">
                    <div >
                      <div class="title">Unpaid  Leaves</div>
                      <br>
                      <div class="title d-flex justify-content-center" *ngIf="selectedUser?.leave?.unpaid ">{{+selectedUser?.leave?.unpaid }}</div>
                    </div>
                  </div>
              </div>
            </nb-card>
          </div>

          <div class="col-md-2 d-flex justify-content-center">
            <nb-card class="mlr-20">
              <div class="p-20 d-flex d-flex justify-content-center">
                  <div class="checkout-bg-color leavecardsize d-flex justify-content-center">
                    <div >
                      <div class="title">Custom  Leaves</div>
                      <br>
                      <div class="title d-flex justify-content-center" *ngIf="selectedUser?.leave?.custom">{{ +selectedUser?.leave?.custom }}</div>
                    </div>
                  </div>
              </div>
            </nb-card>
          </div>
      </div>
    </nb-card>

    <!-- Table -->
    <div *ngIf="!this.isEmployee">
      <nb-card>
        <nb-card-header>
          <div style="justify-content: space-between; display:flex">
            <label class="title h5">Employee Stats</label>
            <span>
              <input id="dateconfirmed" fullWidth
                (ngModelChange)="getallUsersStats($event)"
                [(ngModel)]="currentDate"
                nbInput
                placeholder="currentDate"
                [nbDatepicker]="formpicker1" />
              <nb-datepicker #formpicker1></nb-datepicker>
            </span>
          </div>
        </nb-card-header>
        <div>
            <div >
                <table mat-table [dataSource]="dataSource2" class="mat-elevation-z1 w-100">
                    <!-- Name Column -->
                    <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef >Name</th>
                      <td mat-cell *matCellDef="let element" class="emp-stats-table-column-width">
                        {{ element.name }}
                      </td>
                    </ng-container>

                    <!-- checkin Column -->
                    <ng-container matColumnDef="checkin">
                      <th mat-header-cell *matHeaderCellDef >Check In</th>
                      <td mat-cell *matCellDef="let element" class="emp-stats-table-column-width">
                        {{ element.attendance[0]?.check_in | date:'shortTime'}}
                      </td>
                    </ng-container>

                    <!-- checkout Column -->
                    <ng-container matColumnDef="checkout">
                      <th mat-header-cell *matHeaderCellDef >Check Out</th>
                      <td mat-cell *matCellDef="let element" class="emp-stats-table-column-width">
                        {{ element.attendance[0]?.check_out | date:'shortTime'}}
                      </td>
                    </ng-container>

                    <!-- leave Column -->
                    <ng-container matColumnDef="leave">
                      <th mat-header-cell *matHeaderCellDef >Leave</th>
                        <td mat-cell  *matCellDef="let element" class="emp-stats-table-column-width leave-col-color">
                          {{element?.leaves[0]?.duration | titlecase}}&nbsp;<small *ngIf="element?.leaves[0]?.type"> {{ '(' + element?.leaves[0]?.type  + ')' | titlecase }}</small>
                        </td>
                    </ng-container>

                    <!-- Total working hours Column -->
                    <ng-container matColumnDef="spenthours">
                      <th mat-header-cell *matHeaderCellDef >Total Spent Hours</th>
                      <td mat-cell *matCellDef="let element" class="emp-stats-table-column-width"
                      [ngStyle]="getCellStyle(element.attendance[0]?.working_hours)">
                        {{ element.attendance[0]?.working_hours }}
                      </td>
                    </ng-container>

                    <!-- no record found -->
                    <tr class="mat-row" *matNoDataRow>
                      <td class="mat-cell" colspan="9999">No Record found</td>
                    </tr>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
            </div>
        </div>
      </nb-card>
    </div>

</ng-container>
