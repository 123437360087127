import { Injectable } from "@angular/core";
import { Adapter } from "../adapter";

export class Designation {
    constructor(
    public id : number,
    public name : string,
    public is_active : boolean
    ){}
}

@Injectable({
    providedIn: "root",
  })
  export class DesignationAdapter implements Adapter<Designation> {
    adapt(item: any): Designation {
      return new Designation(
          item.id,
          item.name,
          item.is_active
          );
    }
  }
