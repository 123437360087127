import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { ServiceErrorHandler } from '../models/service-error-handler-decorator';
import { AbstractService } from './common/abstract.service';
@Injectable({
  providedIn: 'root'
})
export class AttendanceService extends AbstractService{

  constructor(private http:HttpClient) {
    super();
   }

  //user verification after sQR scann
  @ServiceErrorHandler()
  public userVerification(email): Observable<any>{
    return this.http.get<any>(`/api/attendance/${email}/verify` ,this.httpOptions)
    .pipe(
      shareReplay()
    );
  }

  //UserEmployeeStats
  @ServiceErrorHandler()
  public allEmployeeStats(date){
    return this.http.get(`/api/admin/attendance/dashboard?date=${date}`,this.httpOptions )
    .pipe(
      map((res:any)=>{
        if(res.success){
          return res.data;
        }
      }),
      shareReplay()
    );
  }

    //UserEmployeedetails
    @ServiceErrorHandler()
    public leaveqouta(){
      return this.http.get(`/api/users/me`,this.httpOptions )
      .pipe(
        map((res:any)=>{
          if(res.success){
            return res.data;
          }
        }),
        shareReplay()
      );
    }

    //All User Leave Qouta//
    // @ServiceErrorHandler()
    // public allusersleaveqouta(id){
    //   return this.http.get(`/api/admin/leaves/users/quota?user_id=${id}`,this.httpOptions )
    //   .pipe(
    //     map((res:any)=>{
    //       if(res.success){
    //         return res.data;
    //       }
    //     }),
    //     shareReplay()
    //   );
    // }

  // user verification after sQR scann
  @ServiceErrorHandler()
  public attendanceMark(email,data){
    return this.http.post(`/api/attendance/${email}/mark` ,data)
    .pipe(
      shareReplay()
    );
  }
  //get user attendance record
  @ServiceErrorHandler()
  public getAttendance(from,to,id): Observable<any>{
    return this.http.get<any>(`/api/admin/attendance/users/${id}?from=${from}&to=${to}` ,this.httpOptions)
    .pipe(
      map((res:any)=>{
        if(res.success){
          return res.data;
        }
      }),
      shareReplay()
    );
  }
  //get user leave statistics count
// get user Attendance
@ServiceErrorHandler()
  public getUserAttendance(from,to,id): Observable<any>{
    return this.http.get<any>(`/api/attendance/users/${id}?from=${from}&to=${to}` ,this.httpOptions)
    .pipe(
      map((res:any)=>{
        if(res.success){
          return res.data;
        }
      }),
      shareReplay()
    );
  }

  @ServiceErrorHandler()
  public getUserAttendanceDashboard(from,to): Observable<any>{
    return this.http.get<any>(`/api/attendance?from=${from}&to=${to}` ,this.httpOptions)
    .pipe(
      map((res:any)=>{
        if(res.success){
          return res.data;
        }
      }),
      shareReplay()
    );
  }

  @ServiceErrorHandler()
  public getUserLeaveStatisticsCount(): Observable<any>{
    return this.http.get<any>(`/api/leaves?count=true` ,this.httpOptions)
    .pipe(
      map((res:any)=>{
        if(res.success){
          return res.data;
        }
      }),
      shareReplay()
    );
  }
  //get user leave statistics
  @ServiceErrorHandler()
  public getUserLeaveStatistics(from,to,id): Observable<any>{
    return this.http.get<any>(`/api/admin/leaves?from=${from}&to=${to}&user_id=${id}` ,this.httpOptions)
    .pipe(
      map((res:any)=>{
        if(res.success){
          return res.data;
        }
      }),
      shareReplay()
    );
  }

  @ServiceErrorHandler()
  public getRoleLeaveStatistics(from,to,id): Observable<any>{
    return this.http.get<any>(`/api/leaves?from=${from}&to=${to}&user_id=${id}` ,this.httpOptions)
    .pipe(
      map((res:any)=>{
        if(res.success){
          return res.data;
        }
      }),
      shareReplay()
    );
  }
}
