import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { tap } from "rxjs";
import Swal from "sweetalert2";
import {
  modalConfig,
  ModalEnum,
} from "../../../../@shared/models/modalConfigs";
import { EmployeeService } from "../../../../@shared/services/employee.service";
import { EmployeeInactiveComponent } from "./employee-inactive/employee-inactive.component";
import { EmployeesActiveComponent } from "./employees-active/employees-active.component";
import { EmployeesDetailComponent } from "./employees-detail/employees-detail.component";
import { EmployeesNewComponent } from "./employees-new/employees-new.component";
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { position: 1, name: "Hydrogen", weight: 1.0079, symbol: "H" },
  { position: 2, name: "Helium", weight: 4.0026, symbol: "He" },
  { position: 3, name: "Lithium", weight: 6.941, symbol: "Li" },
  { position: 4, name: "Beryllium", weight: 9.0122, symbol: "Be" },
  { position: 5, name: "Boron", weight: 10.811, symbol: "B" },
  { position: 6, name: "Carbon", weight: 12.0107, symbol: "C" },
  { position: 7, name: "Nitrogen", weight: 14.0067, symbol: "N" },
  { position: 8, name: "Oxygen", weight: 15.9994, symbol: "O" },
  { position: 9, name: "Fluorine", weight: 18.9984, symbol: "F" },
  { position: 10, name: "Neon", weight: 20.1797, symbol: "Ne" },
];

@Component({
  selector: "ngx-employees",
  templateUrl: "./employees.component.html",
  styleUrls: ["./employees.component.scss"],
})
export class EmployeesComponent implements OnInit, AfterViewInit {
  @ViewChild(EmployeesActiveComponent) component1: any;
  @ViewChild(EmployeeInactiveComponent) component2: any;
  @ViewChild("tabGroup") tabGroup;

  searchKeyword: string = "";
  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  tabChange(event): void {
    event.tabTitle == "Active"
      ? this.component1.getEmployeeList()
      : this.component2.getEmployeeList();
  }

  onSearch(event: any): void {
    console.log("on search", event.target.value);
    this.searchKeyword = event.target.value;
  }

  addEmployee(): void {
    const modal = this.dialog.open(
      EmployeesNewComponent,
      modalConfig(
        {
          data: "",
          panelClass: ["animate__animated", "animate__slideInRight"],
          // disableClose: true,
          // closeOnNavigation: true
        },
        ModalEnum.ModalDefault
      )
    );
    modal.afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.component1.getEmployeeList();
        this.component2.getEmployeeList();
      }
    });
  }
}
