<div *ngIf="isLoaded">
  <div class="row">
    <div class="col-md-6">
      <h6 mat-dialog-title>{{ fullName }}</h6>
    </div>
    <div class="col-md-6">
      <nb-icon
        mat-dialog-close
        class="float-right close-icon"
        icon="close"
      ></nb-icon>
    </div>
  </div>

  <form
    [formGroup]="form"
    (input)="formChange()"
    (ngSubmit)="submitForm()"
    class="form"
  >
    <mat-dialog-content class="mat-typography mh-100">
      <div class="form-group">
        <label for="exampleInputName" class="label"
          >Full Name<span *ngIf="form.get('name')?.errors?.required"
            >*</span
          ></label
        >
        <input
          type="text"
          nbInput
          fullWidth
          id="exampleInputName"
          placeholder="Enter name"
          formControlName="name"
        />
        <small
          class="text-danger"
          *ngIf="
            form.get('name').hasError('required') && form.get('name').touched
          "
        >
          This field is required.
        </small>
        <small
          class="text-danger"
          *ngIf="
            form.get('name').hasError('minlength') && form.get('name').touched
          "
        >
          The minimum length for this field is
          {{ form.get("name").errors.minlength.requiredLength }}
          characters.
        </small>
      </div>
  
      <div class="form-group">
        <label for="exampleInputEmail" class="label"
          >Email<span *ngIf="form.get('email')?.errors?.required"
            >*</span
          ></label
        >
        <input
          type="email"
          nbInput
          fullWidth
          id="exampleInputEmail"
          placeholder="Enter email"
          formControlName="email"
        />
        <small
          class="text-danger"
          *ngIf="
            form.get('email').hasError('required') && form.get('email').touched
          "
        >
          This field is required.
        </small>
        <small
          class="text-danger"
          *ngIf="
            form.get('email').hasError('minlength') && form.get('email').touched
          "
        >
          The minimum length for this field is
          {{ form.get("email").errors.minlength.requiredLength }}
          characters.
        </small>
        <small
          class="text-danger"
          *ngIf="
            (form.get('email').hasError('email') ||
              form.get('email').hasError('pattern')) &&
            form.get('email').touched
          "
        >
          Please provide a valid email address.
        </small>
      </div>

      <div class="form-group">
        <label for="exampleInputNumber" class="label">Phone no#</label>
        <input
          type="text"
          (keyup)="validateNumber()"
          nbInput
          fullWidth
          id="exampleInputNumber"
          placeholder="Enter phone"
          formControlName="phone"
        />
      </div>
      <div class="form-group">
        <nb-checkbox status="success" formControlName="is_active"
          >Active</nb-checkbox
        >
      </div>


       <!-- youtack integration -->
       <div class="form-group">
        <nb-checkbox status="success" formControlName="youtrack_integration">YouTrack</nb-checkbox>
      </div>
       <!-- youtack integration -->

      <!-- Employment Status -->
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="exampleInputNumber" class="label"
              >Employment Status<span>*</span></label
            >
            <nb-select
              fullWidth
              formControlName="employment_status"
              [compareWith]="objectComparisonFunction"
            >
              <nb-option value=""> Choose an option </nb-option>
              <nb-option
                [value]="status.name"
                *ngFor="let status of employmentStatus"
              >
                <ng-container *ngIf="status.is_active">
                  {{ status.name | capitalizeFirstLetter }}</ng-container
                >
              </nb-option>
            </nb-select>
          </div>
        </div>
      </div>

       <!-- Select Roles -->

       <div class="form-group">
        <label for="exampleInputNumber" class="label"
          >Employment Roles<span>*</span></label
        >
        <nb-select
        fullWidth
        formControlName="roles"
        multiple
      >
        <nb-option
          [value]="roles.name"
          *ngFor="let roles of employmentRoles"
          (click)="selectRoles(roles.name)"
        >
          <ng-container>
            {{ roles.name | capitalizeFirstLetter }}</ng-container
          >
        </nb-option>
      </nb-select>
      </div>

      <!-- Select Roles -->

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="datejoined" class="label">Date of Joining</label>
            <input
              id="datejoined"
              fullWidth
              formControlName="date_joined"
              nbInput
              placeholder="Select date"
              [nbDatepicker]="formpicker"
            />
            <nb-datepicker #formpicker></nb-datepicker>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="dateconfirmed" class="label"
              >Confirmation Date</label
            >
            <input
              id="dateconfirmed"
              fullWidth
              formControlName="date_of_permanent"
              nbInput
              placeholder="Select date"
              [nbDatepicker]="formpicker1"
            />
            <nb-datepicker #formpicker1></nb-datepicker>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
            <div class="form-group" >
                <label for="exampleInputNumber" class="label">Designation<span
                       >*</span></label>
                <nb-select fullWidth formControlName="designation" [compareWith]="objectComparisonFunction">
                    <nb-option value="">
                        Choose an option
                    </nb-option>
                    <nb-option [value]="designation.name" *ngFor="let designation of designations">
                        <ng-container *ngIf="designation.is_active"> {{designation.name}}</ng-container>
                    </nb-option>
                </nb-select>
            </div>
        </div>

        <div class="col-md-6">
            <div class="form-group" >
                <label for="exampleInputNumber" class="label">Department<span
                  >*</span></label>
                <nb-select fullWidth formControlName="department" [compareWith]="objectComparisonFunction">
                    <nb-option value="">
                        Choose an option
                    </nb-option>
                    <nb-option [value]="department.name" *ngFor="let department of departments">
                        <ng-container *ngIf="department.is_active"> {{department.name}}</ng-container>
                    </nb-option>
                </nb-select>
            </div>
        </div>
    </div>
      <!-- Change Password -->
      <div class="form-group">
        <nb-checkbox status="false" (change)="changePassword()"
          >Change Password</nb-checkbox
        >
        <!-- Password, Confrim_password  -->
        <div class="row" *ngIf="showChangePassword">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="exampleInputPassword" class="label">Password<span
                            *ngIf="form.get('password')?.errors?.required">*</span></label>
                    <input type="password" nbInput fullWidth id="exampleInputPassword" placeholder="Enter password"
                        formControlName="password">
                    <small class="text-danger"
                        *ngIf="form.get('password').hasError('required')&&form.get('password').touched">
                        This field is required.
                    </small>
                    <small class="text-danger"
                        *ngIf="form.get('password').hasError('minlength')&&form.get('password').touched">
                        The minimum length for this field is
                        {{form.get('password').errors.minlength.requiredLength}}
                        characters.
                    </small>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="exampleInputConfirmPassword" class="label">Confirm Password<span
                            *ngIf="form.get('confirm_password')?.errors?.required">*</span></label>
                    <input type="password" nbInput fullWidth id="exampleInputConfirmPassword"
                        placeholder="Enter password" formControlName="confirm_password">
                    <small class="text-danger"
                        *ngIf="form.get('confirm_password').hasError('required')&&form.get('confirm_password').touched">
                        This field is required.
                    </small>
                    <small class="text-danger"
                        *ngIf="!form.get('confirm_password').hasError('required')&&form.get('confirm_password').hasError('mismatch')&&form.get('confirm_password').touched">
                        Password doesn't match.
                    </small>

                </div>
            </div>
        </div>
        <!-- Password, Confrim_password  -->

      </div>

      <!-- Actions -->
      <div class="d-flex mt-3">
        <div class="mr-auto">
          <button
            type="button"
            (click)="deleteEmployee()"
            class="ml-0"
            [disabled]="currentUserId === id"
            nbButton
            status="danger"
          >
            Delete
          </button>
        </div>
        <div>
          <button type="button" class="mr-2" mat-dialog-close nbButton>
            Cancel
          </button>
        </div>
        <div>
          <button
            class="mr-0"
           
            nbButton
            status="primary"
            class="employee-btn"
            type="submit"
          >
            Update
          </button>
        </div>
      </div>
    </mat-dialog-content>
    <!-- <div class="d-flex mt-3">
            <div class="mr-auto"> <button type="button" (click)="deleteEmployee()" class="ml-0"
                    [disabled]="currentUserId===id" nbButton status="danger">
                    Delete
                </button></div>
            <div> <button type="button" class="mr-2" mat-dialog-close nbButton>
                    Cancel
                </button></div>
            <div> <button class="mr-0" [disabled]="!form.valid||!formChanged" nbButton status="primary"
                    class="employee-btn" type="submit">
                    Update
                </button></div>
        </div> -->
  </form>
</div>

<ngx-mat-progress-bar
  [title]="'Please Wait...'"
  [isLoaded]="isLoaded"
></ngx-mat-progress-bar>
