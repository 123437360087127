import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { CriteriaAdapter } from "../models/criteria.model";
import { ServiceErrorHandler } from "../models/service-error-handler-decorator";
import { AbstractService } from "./common/abstract.service";

@Injectable({
  providedIn: "root",
})
export class EmployeeService extends AbstractService {
  constructor(private http: HttpClient) {
    super();
  }

  @ServiceErrorHandler()
  public getAllEmployees(count, page, status): Observable<any> {
    return this.http
      .get<any>(
        `/api/admin/users?count=${count}&page=${page}&active=${status}&include=personalProfile`,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          if (res.success) {
            return res.data;
          }
        }),
        shareReplay()
      );
  }

  @ServiceErrorHandler()
  public searchEmployee(count, page, status, keyword): Observable<any> {
    return this.http
      .get<any>(
        `/api/admin/users?count=${count}&page=${page}&active=${status}&include=personalProfile&keyword=${keyword}`,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          if (res.success) {
            return res.data;
          }
        }),
        shareReplay()
      );
  }

  @ServiceErrorHandler()
  public getAllRoles(): Observable<any> {
    return this.http.get<any>(`/api/admin/roles`, this.httpOptions).pipe(
      map((res: any) => {
        if (res.success) {
          return res.data;
        }
      }),
      shareReplay()
    );
  }

  @ServiceErrorHandler()
  public getEmployeeById(id): Observable<any> {
    return this.http
      .get<any>(
        `/api/admin/users/${id}?include=personalProfile`,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          if (res.success) {
            return res.data;
          }
        }),
        shareReplay()
      );
  }

  @ServiceErrorHandler()
  public deleteEmployeeById(id): Observable<any> {
    return this.http
      .delete<any>(`/api/admin/users/${id}`, this.httpOptions)
      .pipe(
        map((res: any) => {
          if (res.success) {
            return res.data;
          }
        }),
        shareReplay()
      );
  }

  @ServiceErrorHandler()
  public updateEmployeeStatus(body): Observable<any> {
    return this.http
      .put<any>(
        `/api/admin/users/${body.id}`,
        { is_active: body.is_active },
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          if (res.success) {
            return res.data;
          }
        }),
        shareReplay()
      );
  }

  @ServiceErrorHandler()
  public updateEmployee(id, body): Observable<any> {
    return this.http
      .put<any>(`/api/admin/users/${id}`, body, this.httpOptions)
      .pipe(
        map((res: any) => {
          if (res.success) {
            return res.data;
          }
        }),
        shareReplay()
      );
  }

  @ServiceErrorHandler()
  public getDepartmentsList(): Observable<any> {
    return this.http.get<any>("/api/admin/departments", this.httpOptions).pipe(
      map((res: any) => {
        if (res.success) {
          return res.data;
        }
      }),
      shareReplay()
    );
  }

  @ServiceErrorHandler()
  public getDesignationsList(): Observable<any> {
    return this.http.get<any>("/api/admin/designations", this.httpOptions).pipe(
      map((res: any) => {
        if (res.success) {
          return res.data;
        }
      }),
      shareReplay()
    );
  }

  @ServiceErrorHandler()
  public createEmployee(body): Observable<any> {
    return this.http.post<any>(`/api/admin/users`, body, this.httpOptions).pipe(
      map((res: any) => {
        if (res.success) {
          return res.data;
        }
      }),
      shareReplay()
    );
  }
}
