import { AddDesignationComponent } from './add-designation/add-designation.component';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { tap } from 'rxjs';
import { DesignationService } from '../../../../@shared/services/designation.service';
import { modalConfig, ModalEnum } from '../../../../@shared/models/modalConfigs';
import Swal from 'sweetalert2';

@Component({
  selector: 'ngx-designations',
  templateUrl: './designations.component.html',
  styleUrls: ['./designations.component.scss']
})
export class DesignationsComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [
    "desigantion",
    "action"
  ];
  dataSource: any[]=[];
  isLoaded: boolean = false;
  constructor(public designationService: DesignationService, public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.isLoaded = true;
  }
  ngAfterViewInit(): void {
    this.getDesignationsList();
  }
  getDesignationsList(): void {
    this.isLoaded = false;
    this.designationService.getAllDesignations()
      .pipe(
        tap((res: any) => {
          this.dataSource = res;
          this.isLoaded = true;
        })
      ).subscribe();
  }

  addDesignation(): void {
    const modal = this.dialog.open(
      AddDesignationComponent,
      modalConfig(
        {
          data: "",
          panelClass: ["animate__animated", "animate__slideInRight"],
        },
        ModalEnum.ModalSmall
      )
    );
    modal.afterClosed().subscribe((res) => {
      //refresh tab data based on current active tab
      if(res){
        this.getDesignationsList();
      }
    });
  }

  deleteDesignation(id: any): void {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.isLoaded = false;
        this.designationService
          .deleteDesignation(id)
          .pipe(
            tap((res: any) => {
              this.getDesignationsList();
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
            })
          )
          .subscribe();
      }
    });
  }

  updateDesignation(id: any, data: any): void {
    const modal = this.dialog.open(
      AddDesignationComponent,
      modalConfig(
        {
          data: {data, status: 'update'},
          panelClass: ["animate__animated", "animate__slideInRight"],
        },
        ModalEnum.ModalSmall
      )
    );
    modal.afterClosed().subscribe((res) => {
      //refresh tab data based on current active tab
      if(res){
        this.getDesignationsList();
      }
    });
  }

}
