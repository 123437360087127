import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { tap } from "rxjs";
import Swal from "sweetalert2";
import {
  modalConfig,
  ModalEnum,
} from "../../../../../@shared/models/modalConfigs";
import { EmployeeService } from "../../../../../@shared/services/employee.service";
import { Globals } from "../../../../../gloabls";
import { EmployeesDetailComponent } from "../employees-detail/employees-detail.component";
import { QrDownloadComponent } from "../qr-download/qr-download.component";

@Component({
  selector: "ngx-employee-inactive",
  templateUrl: "./employee-inactive.component.html",
  styleUrls: ["./employee-inactive.component.scss"],
})
export class EmployeeInactiveComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [
    "status",
    "name",
    "desigantion",
    "email",
    "qr",
    "action",
  ];
  dataSource: any;
  pageSize: number = 10;
  length: number;
  pageIndex: number = 0;
  isLoaded: boolean = false;
  currentUserId: number;
  @Input() keyWord: string = "";
  constructor(
    public dialog: MatDialog,
    private employeeService: EmployeeService,
    private globals: Globals
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.getEmployeeList();
  }

  ngOnChanges() {
    if (this.keyWord.length === 0) {
      this.getEmployeeList();
    } else {
      this.isLoaded = false;
      this.employeeService
        .searchEmployee(this.pageSize, this.pageIndex + 1, false, this.keyWord)
        .pipe(
          tap((res: any) => {
            this.currentUserId = this.globals.user.id;
            this.dataSource = res.data;
            this.length = res.meta.total;
            this.isLoaded = true;
          })
        )
        .subscribe();
    }
  }

  getDataOnPageChange(event: PageEvent): void {
    this.isLoaded = false;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.employeeService
      .getAllEmployees(event.pageSize, event.pageIndex + 1, false)
      .pipe(
        tap((res: any) => {
          this.dataSource = res.data;
          this.length = res.meta.total;
          this.isLoaded = true;
        })
      )
      .subscribe();
  }

  searchInput(id: number) {
    const modal = this.dialog.open(
      EmployeesDetailComponent,
      modalConfig(
        {
          data: { id: id },
          panelClass: ["animate__animated", "animate__slideInRight"],
          // disableClose: true,
          // closeOnNavigation: true
        },
        ModalEnum.SidebarSmallResponsive
      )
    );
    modal.afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.getEmployeeList();
      }
    });
  }

  getEmployeeList(): void {
    this.isLoaded = false;
    this.employeeService
      .getAllEmployees(this.pageSize, this.pageIndex + 1, false)
      .pipe(
        tap((res: any) => {
          this.currentUserId = this.globals.user.id;
          this.dataSource = res.data;
          this.length = res.meta.total;
          this.isLoaded = true;
        })
      )
      .subscribe();
  }

  deleteEmployee(id: any): void {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.isLoaded = false;
        this.employeeService
          .deleteEmployeeById(id)
          .pipe(
            tap((res: any) => {
              this.getEmployeeList();
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
            })
          )
          .subscribe();
      }
    });
  }

  onChangeStatus(event: any, employee: any): void {
    switch (event.target.checked) {
      case true:
        employee.is_active = 1;
        break;

      default:
        employee.is_active = 0;
    }
    this.isLoaded = false;
    this.employeeService
      .updateEmployeeStatus(employee)
      .pipe(
        tap((res: any) => {
          this.getEmployeeList();
          Swal.fire("Success!", "Employee status has been updated.", "success");
        })
      )
      .subscribe();
  }

  downloadQr(email: string): void {
    this.dialog.open(
      QrDownloadComponent,
      modalConfig(
        {
          data: { email: email },
          panelClass: ["animate__animated", "animate__slideInRight"],
        },
        ModalEnum.ModalSmall
      )
    );
  }
}
