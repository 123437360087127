<label class="title h4">Designations</label>
<div class="d-flex justify-content-between">
  <div>
  </div>
  <div>
    <button
      nbButton
      status="primary"
      class="employee-btn"
      (click)="addDesignation()"
    >
      Add Designation
    </button>
  </div>
</div>
  <table
    mat-table
    [dataSource]="dataSource"
    class="mt-3 mat-elevation-z1 w-100"
  >
    <!-- Desigantion Column -->
    <ng-container matColumnDef="desigantion">
      <th mat-header-cell *matHeaderCellDef class="col-md-10">Desigantion</th>
      <td mat-cell *matCellDef="let element" (click)="updateDesignation(element.id, element)">
        {{ element?.name }}
      </td>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef class="col-md-2"></th>
      <td mat-cell *matCellDef="let element" class="text-center">
        <i (click)="deleteDesignation(element.id)"
        *ngIf="currentUserId != element.id"
        class="fa fa-trash color-red"
        aria-hidden="true"
      ></i>
      </td>
    </ng-container>
    <!-- no record found -->
    <ng-container matColumnDef="empty">
      <td colspan="10" mat-cell *matFooterCellDef>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr mat-footer-row *matFooterRowDef="['empty']" [class]="dataSource.length > 0 ? 'hidden' : ''"></tr>
  </table>
