import { Component, Inject, OnInit } from "@angular/core";
import {
  Form,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { finalize, tap } from "rxjs";
import Swal from "sweetalert2";
import { EmployeeService } from "../../../../../@shared/services/employee.service";
import { Globals } from "../../../../../gloabls";

@Component({
  selector: "ngx-employees-detail",
  templateUrl: "./employees-detail.component.html",
  styleUrls: ["./employees-detail.component.scss"],
})
export class EmployeesDetailComponent implements OnInit {
  form: FormGroup;
  isLoaded: boolean = false;
  id: number;
  departmentList: any[] = [];
  designationsList: any[] = [];
  empRole: any[] = [];
  designations: number;
  departments: number;
  formChanged: any = false;
  fullName: any;
  currentUserId: number;
  showChangePassword: boolean = false;
  employmentStatus: any = [
    {
      id: 1,
      name: "intern",
      is_active: true,
    },
    {
      id: 2,
      name: "probation",
      is_active: true,
    },
    {
      id: 3,
      name: "permanent",
      is_active: true,
    },
  ];
  employmentRoles = [
    {
      id: 1,
      name: "super admin",
    },
    {
      id: 2,
      name: "admin",
    },
    {
      id: 3,
      name: "user",
    },
  ];
  designation: FormGroup;
  constructor(
    private fb: FormBuilder,
    private employeeService: EmployeeService,
    @Inject(MAT_DIALOG_DATA)
    public data,
    private dialogRef: MatDialogRef<any>,
    private _snackBar: MatSnackBar,
    private globals: Globals
  ) {
    this.form = this.fb.group({
      name: ["", [Validators.required, Validators.minLength(3)]],
      password: [""],
      confirm_password: [
        "",
        [Validators.required, this.passwordMatchValidator()],
      ],
      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ],
      ],
      phone: [""],
      is_active: [false],
      designation: ["", [Validators.required]],
      department: ["", [Validators.required]],
      date_joined: [""],
      date_of_permanent: [""],
      employment_status: ["", [Validators.required]],
      roles: [[]],
      youtrack_integration: [true],
    });
  }

  ngOnInit(): void {
    this.id = this.data.id;
    this.getDepartmentsDesignationsList();
    this.initData();
  }
  initData() {
    this.employeeService.getAllRoles().subscribe((res) => {
      this.employmentRoles = res;
    });
  }

  getEmployeeById(id): void {
    this.employeeService
      .getEmployeeById(id)
      .pipe(
        tap((res: any) => {
          this.currentUserId = this.globals.user.id;
          this.fullName = res.name;
          this.form.patchValue({
            name: res.name,
            email: res.email,
            phone: res.phone_no,
            is_active: res.is_active,
            designation: res?.personalProfile?.designation.name
              ? res?.personalProfile?.designation.name
              : "",
            department: res?.personalProfile?.department.name
              ? res?.personalProfile?.department.name
              : "",
            date_joined: res.date_joined || "",
            date_of_permanent: res.date_of_permanent,
            employment_status: res?.employment_status
              ? res?.employment_status
              : "",
          });
          this.form.patchValue({
            roles: res?.roles,
          });
          this.form.patchValue({
            youtrack_integration: res?.youtrack_integration,
          });

          this.isLoaded = true;
        })
      )
      .subscribe();
  }
  getDepartmentsDesignationsList(): void {
    this.employeeService
      .getDepartmentsList()
      .pipe(
        tap((res: any) => {
          this.departmentList = res;
          this.departments = res;
          this.form.patchValue({
            department: this.departments[0].id,
          });
        }),
        finalize(() => {
          this.employeeService
            .getDesignationsList()
            .pipe(
              tap((res: any) => {
                this.designationsList = res;
                this.designations = res;
                this.form.patchValue({
                  designation: this.designations[0].id,
                });
              }),
              finalize(() => {
                this.getEmployeeById(this.id);
              })
            )
            .subscribe();
        })
      )
      .subscribe();
  }

  submitForm(): void {
    this.isLoaded = false;
    this.departmentList.forEach((ele) => {
      if (ele.name === this.form.get("department").value) {
        this.form.controls["department"].setValue(ele.id);
      }
    });
    this.designationsList.forEach((ele) => {
      if (ele.name === this.form.get("designation").value) {
        this.form.controls["designation"].setValue(ele.id);
      }
    });
    let params;
    params = {
      name: this.form.get("name").value,
      email: this.form.get("email").value,
      phone: this.form.get("phone").value,
      is_active: this.form.get("is_active").value,
      date_joined: this.form.get("date_joined").value,
      date_of_permanent: this.form.get("date_of_permanent").value,
      employment_status: this.form.get("employment_status").value,
      department: {
        id: this.form.get("department").value,
      },
      designation: {
        id: this.form.get("designation").value,
      },

      roles: this.form.get("roles").value,
      youtrack_integration: this.form.get("youtrack_integration").value,
    };
    if (this.showChangePassword) {
      params.password = this.form.get("password").value;
    }

    this.employeeService
      .updateEmployee(this.id, params)
      .pipe(
        tap(
          (res: any) => {
            this.dialogRef.close({ refresh: true });
            this.isLoaded = true;
            Swal.fire("Success!", "Employee has been updated.", "success");
          },
          () => {
            this._snackBar.open("  Email or username already in use.", "Ok", {
              horizontalPosition: "right",
              verticalPosition: "top",
            });
            this.isLoaded = true;
          }
        )
      )
      .subscribe();
  }

  deleteEmployee(): void {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.isLoaded = false;
        this.employeeService
          .deleteEmployeeById(this.id)
          .pipe(
            tap(() => {
              this.dialogRef.close({ refresh: true });
              this.isLoaded = true;
              Swal.fire("Success!", "Employee has been Deleted.", "success");
            })
          )
          .subscribe();
      }
    });
  }

  formChange(): void {
    this.formChanged = true;
  }

  validateNumber() {
    this.form.patchValue({
      phone: this.form.value.phone
        .replace(/[^0-9.]/g, "")
        .replace(/(\..*?)\..*/g, "$1")
        .replace(/^0[^.]/, "0"),
    });
  }
  // custom validator for password confirmation
  passwordMatchValidator(): ValidatorFn {
    return (control: FormControl) => {
      if (!control || !control.parent) {
        return null;
      }
      return control.parent.get("password").value === control.value
        ? null
        : { mismatch: true };
    };
  }
  changePassword() {
    this.showChangePassword = !this.showChangePassword;
  }

  selectRoles(roles) {
    if (this.empRole.length > 0) {
      let add = true;
      this.empRole.forEach((ele, index) => {
        if (ele === roles) {
          this.empRole.splice(index, 1);
          add = false;
        }
      });
      if (add) {
        this.empRole.push(roles);
      }
    } else {
      this.empRole.push(roles);
    }

    this.form.patchValue({
      roles: this.empRole,
    });
  }
}
