import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CalendarOptions } from "@fullcalendar/angular";
import * as moment from "moment";
import { finalize, Observable, tap } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { StatisticsNewComponent } from "../../../../@shared/components/create-modal/statistics-new/statistics-new.component";
import { calenderEventHandler } from "../../../../@shared/models/attendanceModal";
import {
  modalConfig,
  ModalEnum,
} from "../../../../@shared/models/modalConfigs";
import { daysFormat } from "../../../../@shared/models/moment";
import { User } from "../../../../@shared/models/statistics/statistics.model";
import { AttendanceService } from "../../../../@shared/services/attendance.service";
import { StaticticsService } from "../../../../@shared/services/statistics.service";
import { Globals } from "../../../../gloabls";

declare var $: any;
@Component({
  selector: "ngx-statistics",
  templateUrl: "./statistics.component.html",
  styleUrls: ["./statistics.component.scss"],
})
export class StatisticsComponent implements OnInit, AfterViewInit {
  public calendarOptions: any = {};
  public userRecords: any = [];
  public users$: Observable<User>;
  public selectedUser: any = "option";
  public isLoad: boolean = true;
  isEmployee: boolean = false;
  public startDate = moment().startOf("month").format("YYYY-MM-DD");
  public endDate = moment().endOf("month").format("YYYY-MM-DD");
  roles: any[] = [];
  public isInActive: boolean = true;
  constructor(
    private cd: ChangeDetectorRef,
    private _attendanceService: AttendanceService,
    public dialog: MatDialog,
    private _staticticsService: StaticticsService,
    private globals: Globals
  ) {}
  //
  ngOnInit(): void {
    this.roles = this.globals.user.roles;
    if (this.roles.includes("employee") && this.roles.length === 1) {
      this.isEmployee = true;
      this.selectedUser = this.globals.user;
    } else {
      this.loadUsers();
    }
    this.cd.detectChanges();
  }
  //load calender
  ngAfterViewInit(): void {
    this.loadFullCalender(this.globals.user);
  }
  //load all
  public loadUsers() {
    this.users$ = this._staticticsService.getUsers();
  }
  //get user attendance
  public getAttendance(arg, user): void {
    this._attendanceService
      .getAttendance(moment(arg?.startStr).format("YYYY-MM-DD"), moment(arg?.endStr).format("YYYY-MM-DD"), user.id)
      .pipe(
        tap((res) => {
          this.userRecords.push(
            ...calenderEventHandler(res, "attendance", user)
          );
        }),
        finalize(() => this.getLeaves(arg, user))
      )
      .subscribe();
  }

  public getUserAttendance(arg, user): void {
    this._attendanceService
      .getUserAttendanceDashboard(moment(arg?.startStr).format("YYYY-MM-DD"), moment(arg?.endStr).format("YYYY-MM-DD"))
      .pipe(
        tap((res) => {
          this.userRecords.push(
            ...calenderEventHandler(res, "attendance", user)
          );
        }),
        finalize(() => this.getRoleLeaves(arg, user))
      )
      .subscribe();
  }
  //get user leaves
  public getLeaves(arg, user): void {
    this._attendanceService
      .getUserLeaveStatistics(moment(arg?.startStr).format("YYYY-MM-DD"), moment(arg?.endStr).format("YYYY-MM-DD"), user.id)
      .pipe(
        tap((res) => {
          this.userRecords.push(...calenderEventHandler(res, "leaves", user));
        }),
        finalize(() => (this.calendarOptions.events = this.userRecords))
      )
      .subscribe();
  }

  public getRoleLeaves(arg, user): void {
    this._attendanceService
      .getRoleLeaveStatistics(moment(arg?.startStr).format("YYYY-MM-DD"), moment(arg?.endStr).format("YYYY-MM-DD"), user.id)
      .pipe(
        tap((res) => {
          this.userRecords.push(...calenderEventHandler(res, "leaves", user));
        }),
        finalize(() => (this.calendarOptions.events = this.userRecords))
      )
      .subscribe();
  }
  //create modal
  public onCreateEvent(event) {
    const modal = this.dialog.open(
      StatisticsNewComponent,
      modalConfig(
        {
          data: {
            type: "create",
            user: this.selectedUser,
            date: event.start,
            eventDate: event.start,
            optionType: "option",
            entityDisable: false,
          },
          panelClass: ["animate__animated", "animate__slideInRight"],
        },
        ModalEnum.ModalSmall
      )
    );
    modal
      .afterClosed()
      .pipe(
        tap((res) => {
          if (res) {
            this.isLoad = false;
            this.cd.detectChanges();
            this.loadFullCalender(res);
          }
        })
      )
      .subscribe();
  }
  //on event edit
  public onEditEvent(event) {
    const modal = this.dialog.open(
      StatisticsNewComponent,
      modalConfig(
        {
          data: {
            eventId: event._def.publicId,
            type: "edit",
            user: this.selectedUser,
            date: event.start,
            eventDate: event._instance.range,
            extendedProps: event.extendedProps,
            entityDisable: true,
          },
          panelClass: ["animat,e__animated", "animate__slideInRight"],
        },
        ModalEnum.ModalSmall
      )
    );
    modal
      .afterClosed()
      .pipe(
        tap((res) => {
          if (res) {
            this.isLoad = false;
            this.cd.detectChanges();
            this.loadFullCalender(res);
          }
        })
      )
      .subscribe();
  }
  //load calender on the base of user
  public onUserSelection(): void {
    this.isLoad = false;
    this.cd.detectChanges();
    this.loadFullCalender(this.selectedUser);
  }
  //drag attendance
  public handleDrop(event) { }
  //on load calender
  public loadFullCalender(user) {
    let self = this;
    this.isLoad = true;

    if (this.roles.includes("employee") && this.roles.length === 1) {
      this.calendarOptions = {
        firstDay: 1,
        initialView: "dayGridMonth",
        timeZone: environment.timezone,
        weekNumberCalculation: "ISO",
        selectable: self.selectedUser == "option" ? false : true,
        editable: self.selectedUser == "option" ? false : true,
        headerToolbar: {
          right: "dayGridWeek,dayGridMonth,prev,next",
        },
        eventStartEditable: false,
        events: this.userRecords,
        defaultAllDay: false,
        eventTextColor: "#000000",
        select: function (info) {
          if (!self.isEmployee) {
            self.onCreateEvent(info);
          }
        },
        eventContent: function (info) { },
        eventClick: function (info) {
          if (!self.isEmployee) {
            self.onEditEvent(info.event);
          }
        },
        eventDrop: function (info) {
          self.handleDrop(info.event);
        },
        datesSet: function (arg) {
          self.userRecords = [];
          if (self.selectedUser != "option") {
            self.getUserAttendance(arg, user);
          }
        },
      };
    } else {
      this.calendarOptions = {
        firstDay: 1,
        initialView: "dayGridMonth",
        timeZone: environment.timezone,
        weekNumberCalculation: "ISO",
        selectable: self.selectedUser == "option" ? false : true,
        editable: self.selectedUser == "option" ? false : true,
        headerToolbar: {
          right: "dayGridWeek,dayGridMonth,prev,next",
        },
        eventStartEditable: false,
        events: this.userRecords,
        defaultAllDay: false,
        eventTextColor: "#000000",
        select: function (info) {
          self.onCreateEvent(info);
        },
        eventContent: function (info) { },
        eventClick: function (info) {
          self.onEditEvent(info.event);
        },
        eventDrop: function (info) {
          self.handleDrop(info.event);
        },
        datesSet: function (arg) {
          self.userRecords = [];
          if (self.selectedUser != "option") {
            self.getAttendance(arg, user);
          }
        },
      };
    }
  }
}
