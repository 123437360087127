<div>
    <div class="row">
        <div class="col-md-9">
            <h6 mat-dialog-title>Correction Request</h6>
        </div>
        <div class="col-md-3">
            <nb-icon mat-dialog-close class="float-right close-icon" icon="close"></nb-icon>
        </div>
    </div>

    <form [formGroup]="form" (ngSubmit)="data.status=='update'?updateCorrectionReq():createCorrectionReq()"
        class="form">
        <mat-dialog-content class="mat-typography">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="exampleInputName" class="label">Reason<span
                                *ngIf="form.get('reason')?.errors?.required">*</span></label>
                        <textarea maxlength="100" formControlName="reason" rows="3" nbInput fullWidth
                            placeholder="Short detail"></textarea>
                        <small class="text-danger"
                            *ngIf="form.get('reason')?.hasError('required') && form.get('reason')?.touched">
                            This field is required.
                        </small>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="form-group">
                        <label class="label">Check In<span
                                *ngIf="form.get('checkIn')?.errors?.required">*</span></label>
                        <input [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1" nbInput fullWidth
                            placeholder="Select time" formControlName="checkIn" />
                        <owl-date-time [pickerType]="'timer'" #dt1></owl-date-time>
                        <small class="text-danger"
                            *ngIf="form.get('checkIn')?.hasError('required') && form.get('checkIn')?.touched">
                            This field is required.
                        </small>
                    </div>

                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label class="label">Check Out<span
                                *ngIf="form.get('checkOut')?.errors?.required">*</span></label>
                        <input [owlDateTimeTrigger]="dt2" [owlDateTime]="dt2" nbInput fullWidth
                            placeholder="Select time" formControlName="checkOut" />
                        <owl-date-time [pickerType]="'timer'" #dt2></owl-date-time>
                        <small class="text-danger"
                            *ngIf="form.get('checkOut')?.hasError('required') && form.get('checkOut')?.touched">
                            This field is required.
                        </small>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="dateconfirmed" class="label">Attendance Date<span
                                *ngIf="form.get('date')?.errors?.required">*</span></label>
                        <input id="dateconfirmed" fullWidth formControlName="date" nbInput placeholder="Enter date"
                            [nbDatepicker]="formpicker1" />
                        <nb-datepicker #formpicker1></nb-datepicker>
                        <small class="text-danger"
                            *ngIf="form.get('date')?.hasError('required') && form.get('date')?.touched">
                            This field is required.
                        </small>
                    </div>
                </div>

            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button size="small" mat-dialog-close nbButton>
                Cancel
            </button>
            <button size="small" nbButton status="primary" class="employee-btn ml-2" type="submit">
                {{data.status === "update"?'Update':'Request'}}
            </button>
            <button *ngIf="!isEmployee" size="small" nbButton status="warning" class="employee-btn ml-2" type="button" (click)="onStatusChange('accepted')">
                Accept
            </button>
            <button *ngIf="!isEmployee" size="small" nbButton status="danger" class="employee-btn ml-2" type="button" (click)="onStatusChange('rejected')">
                Reject
            </button>

        </mat-dialog-actions>
    </form>
</div>
