import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-attendance-scanner',
  templateUrl: './attendance-scanner.component.html',
  styleUrls: ['./attendance-scanner.component.scss']
})
export class AttendanceScannerComponent implements OnInit {
menu : any[] = []
  constructor() {
    this.menu = [
      {
        title: "Dashboard",
        icon: "home-outline",
        link: "/pages/attendance/dashboard",
        home: true,
      },
      {
        title: "Statistics",
        icon: "calendar-outline",
        link: "/pages/attendance/statistics",
      },
      {
        title: "Spent Hours",
        icon: "clock-outline",
        link: "/pages/attendance/spents",
      },
    ];
   }

  ngOnInit(): void {
  }

}
