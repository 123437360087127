import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-attendance-main',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.scss']
})
export class AttendanceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
